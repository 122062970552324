import React, { ReactNode } from "react";
import { Box, CircularProgress, SxProps } from "@mui/material";

interface LoaderProps {
  size?: number;
  sx?: SxProps;
  children?: ReactNode;
}

export const Loader: React.FC<LoaderProps> = ({ size = 50, sx, children }) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        minHeight: 300,
        flexGrow: 1,
        justifyContent: "center",
        alignItems: "center",
        gap: 2,
        ...sx,
      }}
    >
      <CircularProgress size={size} />
      {children}
    </Box>
  );
};
