"use client";
import React from "react";
import { Box, Divider, Link, Tooltip, Typography } from "@mui/material";
import {
  CardPreviewLoading,
  SizingOptions,
} from "modules/common/components/CardPreviewLoading";
import { FinishEnum } from "modules/cards/model/card";
import { TradeItem } from "modules/tradeLists/model/tradeList";
import { formatDistanceToNow } from "date-fns";
import { languageCountryCodes } from "modules/common/model/models";
import { useSets } from "modules/common/hooks/sets";
import Image from "next/image";
import { useAuth } from "@clerk/nextjs";
import { ClerkAvatar } from "modules/common/components/avatar/ClerkAvatar";
import { CardPreviewSizing } from "utils/styles";
import DynamicRecentTradesContactButton from "modules/tradeLists/components/RecentTrades/DynamicRecentTradesContactButton";
import ConditionChip from "modules/common/components/Table/ConditionChip";

interface Props {
  item: TradeItem;
  sizing?: SizingOptions;
}

export const TradePreviewCard = ({
  item,
  sizing = CardPreviewSizing,
}: Props) => {
  const sets = useSets();
  const { userId, isLoaded } = useAuth();

  return (
    <Box sx={{ position: "relative" }}>
      <Box>
        <CardPreviewLoading
          sizing={sizing}
          card={item.data}
          foil={
            item.finishes.includes(FinishEnum.Foil) ||
            item.finishes.includes(FinishEnum.Etched) ||
            !item.data.finishes.includes(FinishEnum.NonFoil)
          }
        />
        <Box
          sx={{
            bgcolor: "background.paper",
            borderBottomLeftRadius: 4,
            borderBottomRightRadius: 4,
            mt: -1,
          }}
        >
          <Box display="flex" justifyContent="space-between" p={1} pt={2}>
            <Box
              textAlign="right"
              display={"flex"}
              alignItems={"center"}
              justifyContent={`space-between`}
              gap={1}
              width={"100%"}
            >
              <Box display="flex" alignItems="center" gap={2}>
                <Link
                  href={`/users/${item.user.username}`}
                  underline="none"
                  display={"inline-flex"}
                  alignItems={"center"}
                  gap={1}
                >
                  <Tooltip
                    title={`User: ${item.user.username}`}
                    placement={"top"}
                    arrow
                  >
                    <Box>
                      <ClerkAvatar
                        sx={{
                          width: 24,
                          height: 24,
                        }}
                        alt={`Avatar image of the user - ${item.user.username}`}
                        preferredAvatar={item.user.preferredAvatar}
                      />
                    </Box>
                  </Tooltip>
                  <Typography
                    color={"primary"}
                    sx={{
                      mt: 0,
                      p: 0,
                    }}
                  >
                    {item.user.username}
                  </Typography>
                </Link>
              </Box>

              {item.anyPrinting && (
                <Tooltip
                  title={
                    sets.query?.data &&
                    (item.anyPrinting
                      ? "Any set / printing"
                      : sets.query?.data[item.data.set.code].name)
                  }
                  placement={"top"}
                  arrow
                >
                  <Typography
                    component={"span"}
                    color={"secondary"}
                    sx={{
                      fontSize: item.anyPrinting ? "0.9em" : "1.2em",
                    }}
                  >
                    {item.anyPrinting ? (
                      "Any set"
                    ) : (
                      <i
                        className={`ss ss-${item.data.set.keyruneCode.toLowerCase()} ss-grad`}
                      />
                    )}
                  </Typography>
                </Tooltip>
              )}

              <Box display={"flex"} gap={0.5}>
                <Typography>{item.quantity} </Typography>
                <Typography color="text.secondary">x </Typography>

                {item.data.price && (
                  <Typography>
                    €{" "}
                    {item.finishes.includes(FinishEnum.Foil)
                      ? item.data.price.value.foil.toFixed(2)
                      : item.data.price.value.default.toFixed(2)}
                  </Typography>
                )}
              </Box>
            </Box>
          </Box>
          <Divider />
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            p={1}
          >
            <Box display={"flex"} alignItems={"center"} gap={1}>
              <ConditionChip condition={item.condition} />
              <Tooltip title={item.language} placement={"top"} arrow>
                <Box sx={{ display: "flex", justifyContent: "center" }}>
                  <Box
                    position={"relative"}
                    width={24}
                    height={24}
                    overflow={"hidden"}
                  >
                    <Image
                      loading="lazy"
                      fill
                      style={{ objectFit: "contain" }}
                      src={`https://flagcdn.com/${languageCountryCodes[
                        item.language
                      ].toLowerCase()}.svg`}
                      alt={item.language}
                    />
                  </Box>
                </Box>
              </Tooltip>
            </Box>
            <Box display="flex" alignItems="center" gap={1}>
              <Typography variant="body2" color="text.secondary">
                {formatDistanceToNow(new Date(item.updatedAt), {
                  addSuffix: true,
                })}
              </Typography>
            </Box>
          </Box>
          {isLoaded && userId !== item.user.id && (
            <>
              <Divider />
              <Box display="flex" justifyContent="space-between" p={1}>
                <DynamicRecentTradesContactButton item={item} />
              </Box>
            </>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default TradePreviewCard;
