import React from "react";
import { Box, Divider, Skeleton } from "@mui/material";

interface TradePreviewCardSkeletonProps {
  width?: number | string;
  height?: number | string;
}

export const TradePreviewCardSkeleton: React.FC<
  TradePreviewCardSkeletonProps
> = ({ width = 240, height = 464 }) => (
  <Box sx={{ width, height, bgcolor: "background.paper", borderRadius: 2 }}>
    <Skeleton
      variant="rectangular"
      width="100%"
      height={335}
      sx={{ borderRadius: 2 }}
    />
    <Box sx={{ p: 2 }}>
      <Skeleton variant="text" width="80%" height={24} />
      <Divider sx={{ my: 1 }} />
      <Skeleton variant="text" width="50%" height={20} />
      <Divider sx={{ my: 1 }} />
      <Skeleton variant="text" width="50%" height={20} />
    </Box>
  </Box>
);

export default TradePreviewCardSkeleton;
