import React from "react";
import dynamic from "next/dynamic";
import { Loader } from "modules/common/components/Loader";
import { ContactUserButtonProps } from "modules/tradeLists/components/RecentTrades/RecentTradesContactButton";

const DynamicRecentTradesContactButton = ({
  item,
  text,
  small,
}: ContactUserButtonProps) => {
  const RecentTradesContactButton = React.useMemo(
    () =>
      dynamic(
        () =>
          import(
            "modules/tradeLists/components/RecentTrades/RecentTradesContactButton"
          ),
        {
          loading: () => <Loader size={20} sx={{ minHeight: 24 }} />,
        }
      ),
    []
  );
  return <RecentTradesContactButton item={item} small={small} text={text} />;
};

export default DynamicRecentTradesContactButton;
