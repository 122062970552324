"use client";
import React from "react";
import {
  Box,
  Icon,
  Link,
  SxProps,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";
import RecentTradesTableHeader from "modules/tradeLists/components/RecentTrades/RecentTradesTableHeader";
import { formatDistanceToNow } from "date-fns";
import { TradeItem } from "modules/tradeLists/model/tradeList";
import { TooltipCardImage } from "modules/common/components/TooltipCardImage";
import TableNameText from "modules/common/components/TableNameText";
import { compactTableCellStyle } from "modules/common/styles";
import { languageCountryCodes } from "modules/common/model/models";
import Image from "next/image";
import { FinishEnum, MagicCard } from "modules/cards/model/card";
import { useAuth } from "@clerk/nextjs";
import { usePathname } from "next/navigation";
import { useAppUser } from "modules/auth/user/hooks/useAppUser";
import DynamicRecentTradesContactButton from "modules/tradeLists/components/RecentTrades/DynamicRecentTradesContactButton";
import ConditionChip from "modules/common/components/Table/ConditionChip";
import SetTooltip from "modules/common/components/Table/SetTooltip";
import { useSetRecoilState } from "recoil";
import { cardDetailsModalAtom } from "modules/cards/recoil/cards";

interface Props {
  cards: TradeItem[];
}

export const commonTableCellStyle: SxProps = {
  paddingBlock: 0.5,
  paddingInline: 0.1,
  textOverflow: "ellipsis",
  maxWidth: "150px",
  overflow: "hidden",
  fontSize: ".9rem",
  textAlign: "center",
};

export const RecentTradesTable = ({ cards }: Props) => {
  const setCardDetailsDialogAtom = useSetRecoilState(cardDetailsModalAtom);
  const { userId, isLoaded } = useAuth();
  const pathname = usePathname();
  const { appUser, isPending } = useAppUser();

  const handleOpenCardDetails = (card: MagicCard) => {
    setCardDetailsDialogAtom({
      open: true,
      card: card,
    });
  };
  const isUserPage =
    !isPending &&
    appUser &&
    isLoaded &&
    pathname === `/users/${appUser?.username}`;

  return (
    <TableContainer sx={{ maxHeight: "453px" }}>
      <Table stickyHeader>
        <RecentTradesTableHeader />
        <TableBody>
          {cards.slice(3).map((item) => (
            <TableRow
              key={`${item.data.id}-${item.data.setCode}-${item.createdAt}`}
            >
              <TableCell sx={commonTableCellStyle}>{item.quantity}</TableCell>

              <TableCell
                sx={{
                  ...commonTableCellStyle,
                  maxWidth: "130px",
                  textAlign: "left",
                  px: 0.1,
                }}
              >
                <TooltipCardImage
                  card={item.data}
                  isFoil={item.finishes.includes(FinishEnum.Foil)}
                >
                  <Link
                    sx={{
                      cursor: "pointer",
                      color: "primary.main",
                      textDecoration: "none",
                      "&:hover": {
                        textDecoration: "underline",
                      },
                    }}
                    /*href={`/sets/${item.data.setCode}/${encodedCardName(
                      item.data.name
                    )}/${item.data.number}`}*/
                    onClick={() => handleOpenCardDetails(item.data)}
                  >
                    <TableNameText>{item.data.name}</TableNameText>
                  </Link>
                </TooltipCardImage>
              </TableCell>
              <TableCell
                sx={{
                  ...commonTableCellStyle,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <SetTooltip item={item} />
              </TableCell>
              <TableCell sx={compactTableCellStyle}>
                <Typography fontSize={"0.9rem"} color={"secondary"}>
                  {item.data.price ? (
                    <>
                      €{" "}
                      {item.finishes.includes(FinishEnum.Foil)
                        ? item.data.price.value.foil.toFixed(2)
                        : item.data.price.value.default.toFixed(2)}
                    </>
                  ) : null}
                </Typography>
              </TableCell>
              <TableCell sx={{ ...compactTableCellStyle }}>
                {item.finishes.includes(FinishEnum.Foil) && (
                  <Box display={"flex"} justifyContent={"center"}>
                    <Typography fontSize={"0.7rem"} color={"secondary"}>
                      <Tooltip title={"Foil"}>
                        <Icon baseClassName={"ss ss-foil ss-grad ss-2x"} />
                      </Tooltip>
                    </Typography>
                  </Box>
                )}
              </TableCell>
              <TableCell sx={commonTableCellStyle}>
                <Tooltip title={item.language} placement="top" arrow>
                  <Box display="flex" justifyContent="center">
                    <Box
                      position="relative"
                      width={24}
                      height={24}
                      overflow="hidden"
                    >
                      <Image
                        loading="lazy"
                        fill
                        style={{ objectFit: "contain" }}
                        src={`https://flagcdn.com/${languageCountryCodes[
                          item.language
                        ].toLowerCase()}.svg`}
                        alt={item.language}
                      />
                    </Box>
                  </Box>
                </Tooltip>
              </TableCell>
              <TableCell sx={{ ...compactTableCellStyle, textAlign: "center" }}>
                <ConditionChip condition={item.condition} />
              </TableCell>
              <TableCell
                sx={{
                  ...commonTableCellStyle,
                  display: { xs: "none", md: "table-cell" },
                }}
              >
                {formatDistanceToNow(item.updatedAt, { addSuffix: true })}
              </TableCell>
              <TableCell sx={commonTableCellStyle}>
                <Link
                  sx={{
                    cursor: "pointer",
                    color: "primary.main",
                    textDecoration: "none",
                    "&:hover": {
                      textDecoration: "underline",
                    },
                  }}
                  href={`/users/${item.user.username}`}
                >
                  {item.user.username}
                </Link>
              </TableCell>
              {!isUserPage && (
                <TableCell sx={{ ...commonTableCellStyle, px: 1, py: 0.5 }}>
                  {isLoaded && userId !== item.user.id && (
                    <DynamicRecentTradesContactButton item={item} small />
                  )}
                </TableCell>
              )}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default RecentTradesTable;
