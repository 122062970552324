"use client";
import React from "react";
import { Avatar, AvatarProps } from "@mui/material";
import { clerkImage } from "utils/utils";
import useTheme from "@mui/material/styles/useTheme";
import Image from "next/image";

interface ClerkAvatarProps extends AvatarProps {
  preferredAvatar: string;
  width?: number;
  height?: number;
}

export const ClerkAvatar: React.FC<ClerkAvatarProps> = ({
  preferredAvatar,
  width = 28,
  height = 28,
  ...props
}) => {
  const clerkImageUrl = clerkImage(preferredAvatar, width, height);
  const theme = useTheme();
  return (
    <Avatar
      sx={{
        width: width,
        height: height,
        bgcolor: "#fff",
        border: `2px solid ${theme.palette.primary.main}`,
        ...props.sx,
      }}
    >
      <Image
        alt={props.alt || "User Avatar"}
        src={clerkImageUrl}
        width={width}
        height={height}
        quality={100}
        sizes="100vw"
        style={{ objectFit: "cover" }}
      />
    </Avatar>
  );
};
