"use client";
import { useEffect, useState } from "react";

const useSkeletonWidths = () => {
  const [randomWidth, setRandomWidth] = useState<number>(32);
  const [randomManaSymbolsAmount, setRandomManaSymbolsAmount] =
    useState<number>(2);
  const [toRenderOrNotToRender, setToRenderOrNotToRender] =
    useState<boolean>(false);

  useEffect(() => {
    // Generate random width only on the client side
    setRandomWidth(getRandomIntInRange(70, 300));
    setToRenderOrNotToRender(getRandomBooleanWithRate(0.6));
    setRandomManaSymbolsAmount(Math.floor(Math.random() * 5));
  }, []); // Empty dependency array ensures this effect runs only once after the initial render on the client side

  return { randomWidth, randomManaSymbolsAmount, toRenderOrNotToRender };
};

// Helper functions for random generation
const getRandomIntInRange = (min: number, max: number): number => {
  return Math.floor(Math.random() * (max - min + 1) + min);
};

const getRandomBooleanWithRate = (rate: number): boolean => {
  return Math.random() < rate;
};

export default useSkeletonWidths;
