"use client";
import React from "react";
import { Icon, IconButton, Tooltip } from "@mui/material";
import { TradeItem } from "modules/tradeLists/model/tradeList";
import { CollectionCard } from "modules/collections/details/model/collection";
import { useSets } from "modules/common/hooks/sets";
import { isTradeItem } from "utils/utils";
import { AllInclusive } from "@mui/icons-material";

interface SetTooltipProps {
  item: CollectionCard | TradeItem;
}

const SetTooltip: React.FC<SetTooltipProps> = ({ item }) => {
  const sets = useSets();
  const setName =
    (sets.query?.data && sets.query?.data[item.data.set.code]?.name) ||
    (sets.query?.data && sets.query?.data[item.data.setCode]?.name) ||
    "";
  return (
    <Tooltip
      title={
        sets.query?.data &&
        isTradeItem(item) &&
        (item.anyPrinting ? "Any set" : setName)
      }
      arrow
      placement={"top"}
    >
      <IconButton
        aria-label={sets.query?.data ? `Set: ${setName}` : "Set Icon"}
        size="medium"
        color="secondary"
        href={
          isTradeItem(item) && item.anyPrinting
            ? ""
            : `/sets/${item.data.setCode}`
        }
        sx={{
          padding: 1,
          width: 32,
          height: 32,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        {isTradeItem(item) && item.anyPrinting ? (
          <AllInclusive fontSize="inherit" sx={{ fontSize: "20px" }} />
        ) : (
          <Icon
            baseClassName="ss"
            className={`ss-${item.data.set.keyruneCode.toLowerCase()} ss-grad`}
          />
        )}
      </IconButton>
    </Tooltip>
  );
};

export default SetTooltip;
