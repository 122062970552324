import React from "react";
import { Chip, Tooltip } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { CardConditionEnum } from "modules/cards/model/card";
import {
  cardConditionStrings,
  cardConditionStringsFull,
} from "modules/common/model/models";

interface ConditionChipProps {
  condition: CardConditionEnum;
}

const ConditionChip: React.FC<ConditionChipProps> = ({ condition }) => {
  const theme = useTheme();

  return (
    <Tooltip title={cardConditionStringsFull[condition]} placement="top" arrow>
      <Chip
        label={cardConditionStrings[condition]}
        size="small"
        sx={{
          lineHeight: "10px",
          backgroundColor: theme.palette.cardCondition[condition],
          color: theme.palette.primary.contrastText,
        }}
      />
    </Tooltip>
  );
};

export default ConditionChip;
